import { useEffect, useMemo, useRef, useState } from "react";
import AddToBagButton from "./AddToBagButton";
import ImageSlider from "./ImageSlider";
import { AnimatePresence, motion } from "framer-motion";
import CustomSelect from "./CustomSelect";
import { customSort } from "../../utilities/customSort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Price from "./Price";
import { isEmpty } from "../../utilities/isEmpty";
import Breadcrumb from "./Breadcrumb";
// import SelectProductCard from "./SelectProductCard";
import ArtistSection from "./ArtistSection";
import Info from "./Info";
import clsx from "clsx";
// import * as Tooltip from "@radix-ui/react-tooltip";
import {
  activeProductInfoSidebarTabAtom,
  manualClickAtom,
  showProductInfoSidebarAtom,
  showSizeGuideSidebarAtom,
  useBoundStore,
} from "../../store";
import { useAtom, useSetAtom } from "jotai";
import SelectedForYouSlider from "./SelectedForYouSlider";
import CustomSelectDrawer from "./CustomSelectDrawer";
import useMediaQuery from "../../hooks/useMediaQuery";
import SizeGuideSidebar from "../sidebars/SizeGuideSidebar";
import ProductInfoSidebar from "../sidebars/ProductInfoSidebar";
import { useHash } from "react-hash-control";
import { productInfoSidebarTabs } from "../../lib/globals";
import getFrameVariationFromSize from "../../utilities/getFrameVariationFromSize";
import getPassepartoutVariationFromSize from "../../utilities/getPassepartoutVariationFromSize";
import {
  compareSizes,
  compareSizesPassepartoutAndFrames,
} from "../../lib/helpers";

const ACTIVATE_40x50_IMAGE_SLIDER = false;

const SingleProductComponent = ({ frames, passepartout }) => {
  console.log("frames", frames);
  console.log("isOutOfStock", isOutOfStock);
  console.log("isLimitedEditionsProduct", isLimitedEditionsProduct);
  console.log("isWallObjectsProduct", isWallObjectsProduct);

  const [showFrameTooltip, setShowFrameTooltip] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  const [manualClick, setManualClick] = useAtom(manualClickAtom);

  const favourites = useBoundStore((state) => state.favourites);
  const updateFavourites = useBoundStore((state) => state.updateFavourites);

  const hash = useHash();

  // console.log("TESTING frames", frames);
  // console.log("TESTING singleProductVariations", singleProductVariations);

  const availableAmounts =
    singleProductVariations.length > 0 && isGiftCardProduct
      ? singleProductVariations.map(
          ({ variation_id, display_price, variation_description }) => {
            return {
              slug: `${currency.toLowerCase()}-${display_price}`,
              id: variation_id,
              name: `${currency} ${display_price.toFixed(2)}`,
              price: display_price.toFixed(2),
              variationDescription: variation_description
                ? variation_description
                : "",
              type: "amount",
            };
          }
        )
      : [];
  const [selectedAmountVariant, setSelectedAmountVariant] = useState({});

  // console.log("selectedAmountVariant", selectedAmountVariant);

  console.log("TESTING frames", frames);

  const determineSizeName = (size, glassSpecification, windowSize) => {
    if (isPassepartoutProduct) {
      return `${size?.name} ${
        windowSize ? `(window: ${windowSize}) \n` : ""
      } ${currency} ${
        productType === "simple"
          ? parseInt(productPrice).toFixed(2)
          : singleProductVariations
              .find(
                (variation) =>
                  variation.attributes.attribute_pa_size === size?.slug
              )
              ?.display_price.toFixed(2)
      }`;
    } else if (isFrameProduct) {
      return `${size?.name} ${
        glassSpecification ? `(glass: ${glassSpecification})` : ""
      } ${currency} ${
        productType === "simple"
          ? parseInt(productPrice).toFixed(2)
          : singleProductVariations
              .find(
                (variation) =>
                  variation.attributes.attribute_pa_size === size?.slug
              )
              ?.display_price.toFixed(2)
      }`;
    } else {
      return `${size?.name} ${currency} ${
        productType === "simple"
          ? parseInt(productPrice).toFixed(2)
          : singleProductVariations
              .find(
                (variation) =>
                  variation.attributes.attribute_pa_size === size?.slug
              )
              ?.display_price.toFixed(2)
      }`;
    }
  };

  const availableSizes =
    sizes.length > 0
      ? sizes
          .map((size) => {
            const glassSpecification = isFrameProduct
              ? getFrameVariationFromSize(
                  singleProductVariations?.find(
                    (variation) =>
                      variation?.attributes?.attribute_pa_size === size?.slug
                  ),
                  size,
                  frames
                )?.glassSpecification
              : "";

            const windowSize = isPassepartoutProduct
              ? getPassepartoutVariationFromSize(
                  singleProductVariations?.find(
                    (variation) =>
                      variation?.attributes?.attribute_pa_size === size?.slug
                  ),
                  size,
                  passepartout
                )?.windowSize
              : "";

            return {
              slug: size?.slug,
              id:
                productType === "simple"
                  ? productId
                  : singleProductVariations.find(
                      (variation) =>
                        variation.attributes.attribute_pa_size === size?.slug
                    )?.variation_id,
              name: determineSizeName(size, glassSpecification, windowSize),
              price:
                productType === "simple"
                  ? parseInt(productPrice).toFixed(2)
                  : singleProductVariations
                      .find(
                        (variation) =>
                          variation.attributes.attribute_pa_size === size?.slug
                      )
                      ?.display_price.toFixed(2),
              size: size?.name,
              variationDescription: isFrameProduct
                ? singleProductVariations.find(
                    (variation) =>
                      variation.attributes.attribute_pa_size === size?.slug
                  )?.variation_description
                : "",
              type: "size",
            };
          })
          .sort(compareSizes)
      : [];

  console.log("TESTING availableSizes", availableSizes);

  const isSimpleProduct = availableSizes?.length === 1 ? true : false;

  const [selectedSizeVariant, setSelectedSizeVariant] = useState({});

  // console.log("selectedSizeVariant", selectedSizeVariant);

  useEffect(() => {
    if (productType === "simple") {
      setSelectedSizeVariant(availableSizes[0]);
    }
  }, []);

  console.log("passepartout", passepartout);

  const availablePasspartout = useMemo(() => {
    let _passepartoutForSelect = [
      {
        slug: "no-passepartout",
        colorSlug: "",
        id: "",
        name: "Please select print size",
        nameWithoutPrice: "",
        price: 0,
        color: "#efeeec",
        url: "",
        mobileImageUrl: "",
        desktopImageUrl: "",
        passepartoutImage: "",
        content: "",
        shortContent: "",
        posterSizeSlug: "",
        posterSizeName: "",
        size: "",
        type: "passepartout",
        parent: "",
        windowSize: "",
      },
    ];

    if (!isEmpty(selectedSizeVariant)) {
      const selectedSizeObject = sizes.find(
        ({ slug }) => slug === selectedSizeVariant?.slug
      );

      console.log("selectedSizeObject", selectedSizeObject);

      if (
        !isEmpty(passepartout) &&
        !isEmpty(selectedSizeObject) &&
        !isEmpty(selectedSizeObject.matchingPassepartoutSizes)
      ) {
        const passepartoutFlattened = passepartout
          .map((ppt) =>
            ppt.variations
              .map((variation) => {
                return {
                  posterSizeSlug: selectedSizeObject.slug,
                  posterSizeName: selectedSizeObject.name,
                  content: ppt.content,
                  shortContent: ppt.shortContent,
                  desktopImage: ppt.image,
                  mobileImage: ppt.mobileImage,
                  url: ppt.url,
                  color: ppt.color,
                  name: ppt.post_title,
                  categories: ppt.categories,
                  size: selectedSizeObject.matchingPassepartoutSizes.find(
                    (mppts) => mppts.slug === variation.sizeSlug
                  ),
                  sizeForSort:
                    selectedSizeObject.matchingPassepartoutSizes.find(
                      (mppts) => mppts.slug === variation.sizeSlug
                    )?.name,
                  passepartoutImagePortrait: ppt.passepartoutImagePortrait,
                  passepartoutImagePortraitNew:
                    ppt.passepartoutImagePortraitNew,
                  passepartoutImageLandscape: ppt.passepartoutImageLandscape,
                  passepartoutImageSquare: ppt.passepartoutImageSquare,
                  parent: ppt,
                  windowSize: variation.windowSize,
                  ...variation,
                };
              })
              .sort(compareSizesPassepartoutAndFrames)
          )
          .flat();

        const _availablePasspartout = passepartoutFlattened.filter((ppt) =>
          selectedSizeObject.matchingPassepartoutSizes.find(
            (sso) => sso.slug === ppt.sizeSlug
          )
        );

        console.log("passepartoutFlattened", passepartoutFlattened);
        console.log("_availablePasspartout", _availablePasspartout);

        const determinePassepartoutImage = (passepartout) => {
          switch (spDimension) {
            case "portrait":
              return passepartout?.passepartoutImagePortrait;
            case "landscape":
              return passepartout?.passepartoutImageLandscape;
            case "square":
              return passepartout?.passepartoutImageSquare;

            default:
              break;
          }
        };

        const determinePassepartoutImageNew = (passepartout) => {
          switch (spDimension) {
            case "portrait":
              return passepartout?.passepartoutImagePortraitNew;
            case "landscape":
              return passepartout?.passepartoutImageLandscape;
            case "square":
              return passepartout?.passepartoutImageSquare;

            default:
              break;
          }
        };

        if (_availablePasspartout && _availablePasspartout?.length > 0) {
          _passepartoutForSelect = [
            ..._passepartoutForSelect,
            ..._availablePasspartout.map((ppt) => {
              return {
                slug: `${ppt.categories[0].slug}-${ppt.color[0].slug}-${ppt.size.slug}`,
                colorSlug: `${ppt.categories[0].slug}-${ppt.color[0].slug}`,
                id: ppt.id,
                name: `${ppt.name} ${ppt.size.name} ${
                  ppt.windowSize ? `\n` : ""
                } ${
                  ppt.windowSize ? `(window: ${ppt.windowSize}) ` : ""
                } + ${currency} ${parseInt(ppt.price).toFixed(2)}`,
                nameWithoutPrice: `${ppt.name} ${ppt.size.name}`,
                price: parseInt(ppt.price).toFixed(2),
                color: ppt.color[0].description,
                size: ppt.size.slug,
                url: ppt.url,
                mobileImageUrl: ppt.mobileImage,
                desktopImageUrl: ppt.desktopImage,
                passepartoutImage:
                  productId === 14609 || productId === 195162
                    ? determinePassepartoutImageNew(ppt)
                    : determinePassepartoutImage(ppt),
                content: ppt.content,
                shortContent: ppt.shortContent,
                posterSizeSlug: ppt.posterSizeSlug,
                posterSizeName: ppt.posterSizeName,
                type: "passepartout",
                windowSize: ppt?.windowSize,
                parent: {
                  name: ppt.name,
                  slug: `${ppt.categories[0].slug}-${ppt.color[0].slug}`,
                  description: ppt.shortContent,
                  url: ppt.url,
                  desktopImage: ppt.desktopImage,
                  mobileImage: ppt.mobileImage,
                  gallery: ppt.parent.gallery,
                },
              };
            }),
          ];
        }
      }
    }

    const determineDefaultLabel = () => {
      if (isEmpty(selectedSizeVariant)) {
        return "Please select print size";
      } else {
        if (_passepartoutForSelect && _passepartoutForSelect?.length === 1) {
          return "Passepartout not available for this size";
        } else {
          return "No passepartout";
        }
      }
    };

    const formattedPassepartoutForSelect = [..._passepartoutForSelect]?.map(
      (passepartout) => {
        if (passepartout?.slug === "no-passepartout") {
          return { ...passepartout, name: determineDefaultLabel() };
        } else {
          return passepartout;
        }
      }
    );

    return formattedPassepartoutForSelect;
  }, [passepartout, selectedSizeVariant?.slug]);

  console.log("availablePasspartout", availablePasspartout);

  const [selectedPassepartoutVariant, setSelectedPassepartoutVariant] =
    useState({});

  // console.log("selectedPassepartoutVariant", selectedPassepartoutVariant);

  const availableFrames = useMemo(() => {
    // console.log("running avail frames logic");
    let _framesForSelect = [
      {
        slug: "no-frame",
        colorSlug: "",
        id: "",
        name: "No frame",
        nameWithoutPrice: "",
        price: 0,
        color: "#efeeec",
        url: "",
        mobileImageUrl: "",
        desktopImageUrl: "",
        frameImage: "",
        frameImage40x50: "",
        content: "",
        shortContent: "",
        posterSizeSlug: "",
        posterSizeName: "",
        size: "",
        type: "frame",
        variationDescription: "",
        glassSpecification: "",
        parent: "",
      },
    ];

    if (!isEmpty(selectedSizeVariant)) {
      console.log("all sizes to looop", sizes);
      console.log("selectedSizeVariant", selectedSizeVariant);
      console.log(
        "yololo selectedPassepartoutVariant",
        selectedPassepartoutVariant
      );
      console.log(
        "!isEmpty(selectedPassepartoutVariant)",
        !isEmpty(selectedPassepartoutVariant)
      );
      console.log("yololo passepartoutSizes", passepartoutSizes);
      console.log("yololo sizes", sizes);
      let sizesForMap =
        !isEmpty(selectedPassepartoutVariant) &&
        selectedPassepartoutVariant?.slug !== "no-passepartout"
          ? passepartoutSizes
          : sizes;
      console.log("the sizes to map", sizesForMap);
      const selectedSizeObject = sizesForMap.find(
        ({ slug }) =>
          slug ===
          (!isEmpty(selectedPassepartoutVariant) &&
          selectedPassepartoutVariant?.size
            ? selectedPassepartoutVariant?.size
            : selectedSizeVariant?.slug)
      );
      console.log("yolo frames", frames);
      console.log("selectedSizeObject", selectedSizeObject);
      console.log("selectedSizeVariant", selectedSizeVariant);
      console.log("!isEmpty(frames)", !isEmpty(frames));
      console.log("!isEmpty(selectedSizeObject)", !isEmpty(selectedSizeObject));
      console.log(
        "!isEmpty(frames) && !isEmpty(selectedSizeObject)",
        !isEmpty(frames) && !isEmpty(selectedSizeObject)
      );
      if (!isEmpty(frames) && !isEmpty(selectedSizeObject)) {
        const _availableFrames = frames.filter((frame) => {
          console.log("frameframeframe", frame);
          console.log(
            "the matching frame size",
            selectedSizeObject.matchingFrameSizes
          );
          const frameMatchForSelectedSizeVariant = frame.variations.find(
            ({ sizeSlug }) =>
              sizeSlug === selectedSizeObject.matchingFrameSizes?.[0]?.slug
          );

          console.log(
            "frameMatchForSelectedSizeVariant",
            frameMatchForSelectedSizeVariant
          );

          frame.posterSizeSlug = selectedSizeObject.slug;
          frame.posterSizeName = selectedSizeObject.name;
          frame.selectedSize = selectedSizeObject.matchingFrameSizes?.[0];
          frame.selectedVariationId = frameMatchForSelectedSizeVariant?.id;
          frame.selectedVariationPrice = parseInt(
            frameMatchForSelectedSizeVariant?.price
          );
          frame.selectedVariationImage =
            frameMatchForSelectedSizeVariant?.frameImage;
          frame.selectedVariationDescription =
            frameMatchForSelectedSizeVariant?.description;
          frame.selectedGlassSpecification =
            frameMatchForSelectedSizeVariant?.glassSpecification;

          return frame.size.find(
            (s) => s.slug === selectedSizeObject.matchingFrameSizes?.[0]?.slug
          );
        });

        console.log("_availableFrames", _availableFrames);

        // const framesSortedByType = Object.values(
        //   _availableFrames.reduce((acc, frame) => {
        //     const type = frame.frameType[0]?.name;

        //     if (!acc[type]) {
        //       acc[type] = {
        //         type: type,
        //         frames: [frame],
        //       };
        //     } else {
        //       acc[type].frames.push(frame);
        //     }

        //     return acc;
        //   }, {})
        // );

        const determineFrameImage = (frame) => {
          switch (spDimension) {
            case "portrait":
              return frame?.frameImagePortrait;
            case "landscape":
              return frame?.frameImageLandscape;
            case "square":
              return frame?.frameImageSquare;

            default:
              break;
          }
        };

        const determineFrameImageNew = (frame) => {
          switch (spDimension) {
            case "portrait":
              return frame?.frameImagePortraitNew;
            case "landscape":
              return frame?.frameImageLandscape;
            case "square":
              return frame?.frameImageSquare;

            default:
              break;
          }
        };

        if (_availableFrames && _availableFrames?.length > 0) {
          _framesForSelect = [
            ..._framesForSelect,
            ..._availableFrames.map((frame) => {
              console.log("frame", frame, selectedSizeVariant);

              return {
                slug: `${frame.categories[0].slug}-${frame.frameType[0].slug}-${frame.color[0].slug}-${frame.selectedSize.slug}`,
                colorSlug: `${frame.categories[0].slug}-${frame.frameType[0].slug}-${frame.color[0].slug}`,
                id: frame.selectedVariationId,
                name: `${frame.post_title} ${frame.selectedSize.name} ${
                  frame.selectedGlassSpecification ? `\n` : ""
                } ${
                  frame.selectedGlassSpecification
                    ? `(glass: ${frame.selectedGlassSpecification})`
                    : ""
                } + ${currency} ${frame.selectedVariationPrice.toFixed(2)}`,
                nameWithoutPrice: `${frame.post_title} ${frame.selectedSize.name}`,
                price: frame.selectedVariationPrice.toFixed(2),
                color: frame.color[0].description,
                frameSwatch: frame.color[0].frameSwatch,
                size: frame.selectedSize.slug,
                url: frame.url,
                mobileImageUrl: frame.mobileImage,
                desktopImageUrl: frame.image,
                frameImage:
                  selectedSizeVariant?.slug === "400x500mm" &&
                  ACTIVATE_40x50_IMAGE_SLIDER &&
                  isSimpleProduct
                    ? frame?.frameImagePortrait40x50
                    : productId === 14609 || productId === 195162
                    ? determineFrameImageNew(frame)
                    : determineFrameImage(frame),
                content: frame.content,
                shortContent: frame.shortContent,
                posterSizeSlug: frame.posterSizeSlug,
                posterSizeName: frame.posterSizeName,
                variationDescription: frame.selectedVariationDescription,
                type: "frame",
                glassSpecification: frame.selectedGlassSpecification,
                parent: {
                  name: frame.post_title,
                  slug: `${frame.categories[0].slug}-${frame.frameType[0].slug}-${frame.color[0].slug}`,
                  description: frame.shortContent,
                  url: frame.url,
                  desktopImage: frame.image,
                  mobileImage: frame.mobileImage,
                  gallery: frame.gallery,
                },
              };
            }),
          ];
        }
      }
    }

    console.log("_framesForSelect", _framesForSelect);

    const determineDefaultLabel = () => {
      if (isEmpty(selectedSizeVariant)) {
        return "Please select print size";
      } else {
        if (_framesForSelect && _framesForSelect?.length === 1) {
          return "Frame not available for this size";
        } else {
          return "No frame";
        }
      }
    };

    const formattedFramesForSelect = [..._framesForSelect]?.map((frame) => {
      if (frame?.slug === "no-frame") {
        return { ...frame, name: determineDefaultLabel() };
      } else {
        return frame;
      }
    });

    return formattedFramesForSelect;
  }, [frames, selectedSizeVariant?.slug, selectedPassepartoutVariant?.slug]);

  console.log("availableFrames", availableFrames);

  const [selectedFrameVariant, setSelectedFrameVariant] = useState({});

  // console.log("selectedFrameVariant", selectedFrameVariant);

  // Scenario handling for size, frame, passepartout etc.
  useEffect(() => {
    if (!isEmpty(selectedSizeVariant)) {
      if (
        !isEmpty(selectedPassepartoutVariant) &&
        isEmpty(selectedFrameVariant)
      ) {
        if (!selectedPassepartoutVariant?.slug.includes("no-")) {
          if (
            selectedSizeVariant.slug !==
            selectedPassepartoutVariant?.posterSizeSlug
          ) {
            // console.log("111", availablePasspartout);

            setSelectedPassepartoutVariant(
              availablePasspartout.find(
                ({ posterSizeSlug, colorSlug }) =>
                  posterSizeSlug === selectedSizeVariant?.slug &&
                  colorSlug === selectedPassepartoutVariant?.colorSlug
              ) ||
                availablePasspartout.find(
                  ({ posterSizeSlug }) =>
                    posterSizeSlug === selectedSizeVariant?.slug
                )
            );
            setManualClick(false);
          }
        }
      }

      if (
        isEmpty(selectedPassepartoutVariant) &&
        !isEmpty(selectedFrameVariant)
      ) {
        // console.log("222");
        if (!selectedFrameVariant.slug?.includes("no-")) {
          if (
            selectedSizeVariant.slug !== selectedFrameVariant?.posterSizeSlug
          ) {
            const frameMatch = availableFrames.find(
              ({ posterSizeSlug }) =>
                posterSizeSlug === selectedSizeVariant?.slug
            );

            setSelectedFrameVariant(frameMatch);
            setManualClick(false);
          }
        }
      }

      if (
        !isEmpty(selectedPassepartoutVariant) &&
        !isEmpty(selectedFrameVariant)
      ) {
        if (
          !selectedPassepartoutVariant?.slug.includes("no-") &&
          !selectedFrameVariant.slug.includes("no-")
        ) {
          if (
            selectedSizeVariant.slug !==
            selectedPassepartoutVariant?.posterSizeSlug
          ) {
            // console.log("Change passepartout based on poster size");

            const passepartoutMatch =
              availablePasspartout.find(({ posterSizeSlug, colorSlug }) =>
                colorSlug
                  ? colorSlug === selectedPassepartoutVariant.colorSlug &&
                    posterSizeSlug === selectedSizeVariant?.slug
                  : posterSizeSlug === selectedSizeVariant?.slug
              ) ?? {};

            setSelectedPassepartoutVariant(passepartoutMatch);
            setManualClick(false);
          }

          if (
            selectedSizeVariant.slug ===
              selectedPassepartoutVariant?.posterSizeSlug &&
            selectedFrameVariant.size !== selectedPassepartoutVariant?.size
          ) {
            // console.log("Change frame based on passepartout or poster size");

            const frameMatch =
              availableFrames.find(({ size, colorSlug }) =>
                colorSlug
                  ? colorSlug === selectedFrameVariant.colorSlug &&
                    size === selectedPassepartoutVariant?.size
                  : size === selectedPassepartoutVariant?.size
              ) ?? {};

            setSelectedFrameVariant(frameMatch);
            setManualClick(false);
          }
        }

        if (
          selectedPassepartoutVariant?.slug.includes("no-") &&
          !isEmpty(selectedFrameVariant) &&
          !selectedFrameVariant?.slug.includes("no-") &&
          manualClick !== "frame"
        ) {
          const frameMatch =
            availableFrames.find(({ size, colorSlug }) =>
              colorSlug
                ? colorSlug === selectedFrameVariant.colorSlug &&
                  size === selectedSizeVariant?.slug
                : size === selectedSizeVariant?.slug
            ) ?? {};

          setSelectedFrameVariant(frameMatch);
          setManualClick(false);
        }
      }
    }
  }, [
    selectedSizeVariant?.slug,
    selectedPassepartoutVariant?.slug,
    selectedFrameVariant?.slug,
    [...availablePasspartout],
    [...availableFrames],
    manualClick,
  ]);

  const [totalPrice, setTotalPrice] = useState(null);

  useEffect(() => {
    const _totalPrice = [
      ...(selectedAmountVariant?.price
        ? [parseInt(selectedAmountVariant.price)]
        : []),
      ...(selectedSizeVariant?.price
        ? [parseInt(selectedSizeVariant.price)]
        : []),
      ...(selectedPassepartoutVariant?.price
        ? [parseInt(selectedPassepartoutVariant.price)]
        : []),
      ...(selectedFrameVariant?.price
        ? [parseInt(selectedFrameVariant.price)]
        : []),
    ]
      .reduce((acc, curr) => acc + curr, 0)
      ?.toFixed(2);

    setTotalPrice(_totalPrice);
  }, [
    selectedAmountVariant?.slug,
    selectedSizeVariant?.slug,
    selectedPassepartoutVariant?.slug,
    selectedFrameVariant?.slug,
  ]);

  const isFavourite = () => {
    const match =
      favourites && favourites.length > 0
        ? favourites.find(
            (favourite) => parseInt(favourite.id) === parseInt(productId)
          )
        : false;

    return match;
  };

  const favouritesHandler = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(urlSearchParams.entries());

    updateFavourites(
      {
        id: productId,
        isVariable: productType === "variable" ? true : false,
        variationId: !!selectedSizeVariant?.id ? selectedSizeVariant?.id : "",
        variationSize: !!selectedSizeVariant?.name
          ? selectedSizeVariant?.name
          : "",
        variationSizeSlug: !!selectedSizeVariant?.size
          ? selectedSizeVariant?.size
          : "",
      },
      favourites,
      searchParams
    );
  };

  const sm = useMediaQuery("(min-width: 640px)");

  const [alignTop, setAlignTop] = useState(false);

  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef?.current?.clientHeight > 38) {
      setAlignTop(true);
    } else {
      setAlignTop(false);
    }
  }, [titleRef]);

  const setShowSizeGuideSidebar = useSetAtom(showSizeGuideSidebarAtom);

  const setShowProductInfo = useSetAtom(showProductInfoSidebarAtom);

  const setActiveProductInfoSidebarTab = useSetAtom(
    activeProductInfoSidebarTabAtom
  );

  useEffect(() => {
    console.log("hashhashhash", hash);

    switch (hash) {
      case "framing":
        setShowSizeGuideSidebar(false);
        setShowProductInfo(true);
        setActiveProductInfoSidebarTab(productInfoSidebarTabs[0].slug);
        break;

      case "frame-and-passepartout":
        setShowSizeGuideSidebar(false);
        setShowProductInfo(true);
        setActiveProductInfoSidebarTab(productInfoSidebarTabs[1].slug);
        break;

      case "shipping":
        setShowSizeGuideSidebar(false);
        setShowProductInfo(true);
        setActiveProductInfoSidebarTab(productInfoSidebarTabs[2].slug);
        break;

      case "size-guide":
        setShowProductInfo(false);
        setShowSizeGuideSidebar(true);
        break;

      default:
        break;
    }
  }, [hash]);

  useEffect(() => {
    if (isGiftCardProduct) {
      setShowDescription(true);
    }
  }, []);

  const determineSelectedForYouProductsLink = () => {
    if (isGiftCardProduct) {
      return "/artprints";
    } else if (isPassepartoutProduct) {
      return "/passepartout";
    } else if (isFrameProduct) {
      return "/frames";
    } else if (isLimitedEditionsProduct) {
      return "/limited-editions";
    } else if (isWallObjectsProduct) {
      return "/wall-objects";
    } else {
      return "/artprints";
    }
  };

  return (
    <>
      <div className="flex flex-col">
        {sm && <Breadcrumb />}
        <motion.div className="flex flex-col md:flex-row mt-5 sm:mt-0">
          <div className="flex flex-col w-full md:w-[53%]">
            <div className="flex sm:hidden justify-between mb-3 w-full">
              <h1
                ref={titleRef}
                className="font-body-medium text-[29px] leading-[38px] tracking-[0.42px] text-black my-0"
              >
                {singleProductName}
              </h1>
              <button
                className={clsx(
                  "relative flex cursor-pointer",
                  alignTop ? "items-start mt-2" : "items-center"
                )}
                onClick={() => favouritesHandler()}
              >
                <FontAwesomeIcon
                  icon={["fal", "heart"]}
                  className={clsx(
                    "w-5 h-5 transition duration-300 ease-in-out",
                    isFavourite() ? "opacity-0" : "opacity-100"
                  )}
                />
                <FontAwesomeIcon
                  icon={["fas", "heart"]}
                  className={clsx(
                    "w-5 h-5 absolute left-0 transition duration-300 ease-in-out",
                    isFavourite()
                      ? "scale-100 opacity-100"
                      : "scale-150 opacity-0"
                  )}
                />
              </button>
            </div>
            <a
              className="font-display-regular text-sm tracking-[0.6px] text-gray-light uppercase mb-4 sm:mb-6 inline-block sm:hidden"
              href={artistPostLink ?? ""}
              dangerouslySetInnerHTML={{
                __html: singleProductArtist?.[0]?.name,
              }}
            />
            <div
              data-label="Description mobile"
              className="relative block sm:hidden"
            >
              <motion.div
                className="wysiwyg-content font-body-regular text-sm text-black overflow-hidden [&>*:last-child]:mb-0"
                initial="collapsed"
                animate={showDescription ? "open" : "collapsed"}
                variants={{
                  open: { height: "auto" },
                  collapsed: { height: sm ? 72 : 52 },
                }}
                transition={{ type: "tween" }}
                dangerouslySetInnerHTML={{
                  __html:
                    singleProductDescription +
                    (selectedSizeVariant &&
                    selectedSizeVariant?.variationDescription
                      ? selectedSizeVariant.variationDescription
                      : ""),
                }}
              />
              <AnimatePresence>
                {!showDescription && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: "tween" }}
                    className="absolute top-0 left-0 w-full h-full z-50 bg-gradient-to-t from-white"
                  />
                )}
              </AnimatePresence>
            </div>
            <motion.button
              animate={{ marginTop: showDescription ? 12 : 8 }}
              data-label="Description CTA mobile"
              className="font-display-bold text-xs tracking-[0.54px] text-black uppercase underline underline-offset-4 inline-block sm:hidden self-start mb-6"
              onClick={() => setShowDescription((prev) => !prev)}
            >
              {`${showDescription ? "Close" : "Read more"}`}
            </motion.button>
            <div className="flex items-start">
              <ImageSlider
                selectedSizeVariant={selectedSizeVariant}
                selectedPassepartoutVariant={selectedPassepartoutVariant}
                selectedFrameVariant={selectedFrameVariant}
                dimension={spDimension}
                isSimpleProduct={isSimpleProduct}
              />
            </div>
            {!isGiftCardProduct &&
              !isWallObjectsProduct &&
              !isLimitedEditionsProduct &&
              artWallPushSections &&
              artWallPushSections?.length > 0 && (
                <div className="mt-6 sm:mt-8 hidden sm:flex flex-col gap-4">
                  {artWallPushSections?.map((artWallPushSection, index) => (
                    <div key={index}>
                      <h3 className="font-body-regular text-lg text-black whitespace-pre-line mt-0 mb-1">
                        {artWallPushSection?.title}
                      </h3>
                      <div
                        className="text-[#787878] font-body-regular text-sm [&>*]:m-0"
                        dangerouslySetInnerHTML={{
                          __html: artWallPushSection?.description,
                        }}
                      />
                      <a
                        href={
                          artWallPushSection?.["cta_button"]?.[
                            "cta_custom_link"
                          ] || artWallPushSection?.["cta_button"]?.["cta_link"]
                        }
                        className="mt-7 inline-block font-display-bold text-xs tracking-[0.54px] text-black uppercase underline underline-offset-4"
                      >
                        {artWallPushSection?.["cta_button"]?.["cta_name"]}
                      </a>
                    </div>
                  ))}
                </div>
              )}
          </div>
          <div className="w-full md:w-[47%] px-0 md:px-4 lg:pl-6 lg:pr-8 xl:pr-16 flex flex-col items-start">
            <div className="justify-between my-3 w-full hidden sm:flex">
              <h1
                ref={titleRef}
                className="font-body-medium text-[29px] leading-[38px] tracking-[0.42px] text-black my-0"
              >
                {singleProductName}
              </h1>
              <button
                className={clsx(
                  "relative flex cursor-pointer",
                  alignTop ? "items-start mt-2" : "items-center"
                )}
                onClick={() => favouritesHandler()}
              >
                <FontAwesomeIcon
                  icon={["fal", "heart"]}
                  className={clsx(
                    "w-5 h-5 transition duration-300 ease-in-out",
                    isFavourite() ? "opacity-0" : "opacity-100"
                  )}
                />
                <FontAwesomeIcon
                  icon={["fas", "heart"]}
                  className={clsx(
                    "w-5 h-5 absolute left-0 transition duration-300 ease-in-out",
                    isFavourite()
                      ? "scale-100 opacity-100"
                      : "scale-150 opacity-0"
                  )}
                />
              </button>
            </div>
            <p className="font-display-regular text-sm tracking-[0.6px] text-gray-light uppercase hidden sm:inline-block mb-6">
              <a
                href={artistPostLink ?? ""}
                dangerouslySetInnerHTML={{
                  __html: singleProductArtist?.[0]?.name,
                }}
              />
            </p>
            <div
              data-label="Description desktop"
              className="relative mt-6 sm:mt-0 hidden sm:block"
            >
              <motion.div
                className="wysiwyg-content font-body-regular text-sm text-black overflow-hidden [&>*:last-child]:mb-0"
                initial={
                  !isWallObjectsProduct && !isLimitedEditionsProduct
                    ? "collapsed"
                    : "open"
                }
                animate={
                  showDescription ||
                  isWallObjectsProduct ||
                  isLimitedEditionsProduct
                    ? "open"
                    : "collapsed"
                }
                variants={{
                  open: { height: "auto" },
                  collapsed: { height: sm ? 72 : 52 },
                }}
                transition={{ type: "tween" }}
                dangerouslySetInnerHTML={{
                  __html:
                    singleProductDescription +
                    (selectedSizeVariant &&
                    selectedSizeVariant?.variationDescription
                      ? selectedSizeVariant.variationDescription
                      : ""),
                }}
              />
              <AnimatePresence>
                {!showDescription &&
                  !isWallObjectsProduct &&
                  !isLimitedEditionsProduct && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ type: "tween" }}
                      className="absolute top-0 left-0 w-full h-full z-50 bg-gradient-to-t from-white"
                    />
                  )}
              </AnimatePresence>
            </div>
            {!isWallObjectsProduct && !isLimitedEditionsProduct && (
              <motion.button
                animate={{ marginTop: showDescription ? 12 : 8 }}
                className="font-display-bold text-xs tracking-[0.54px] text-black uppercase underline underline-offset-4 hidden sm:block"
                onClick={() => setShowDescription((prev) => !prev)}
              >
                {`${showDescription ? "Close" : "Read more"}`}
              </motion.button>
            )}
            {!isOutOfStock && (
              <div className="w-full flex flex-col [&>*:not(:first-child):not(.prod-card):not(.select-drawer)]:mt-4 mt-4">
                {!isGiftCardProduct &&
                  !isWallObjectsProduct &&
                  !isLimitedEditionsProduct && (
                    <div className="flex justify-end">
                      <button
                        className="flex gap-1 items-center group"
                        onClick={() => setShowSizeGuideSidebar(true)}
                      >
                        <span className="font-body-regular text-xs tracking-[0.6px] text-gray-light group-hover:text-black transition-colors duration-200 ease-in-out">
                          Size guide
                        </span>
                        <FontAwesomeIcon
                          className="text-gray-light group-hover:text-black transition-colors duration-200 ease-in-out"
                          icon={["fal", "info-circle"]}
                        />
                      </button>
                    </div>
                  )}
                {isGiftCardProduct && (
                  <>
                    <CustomSelect
                      data={availableAmounts}
                      state={selectedAmountVariant?.slug}
                      setStateCallback={setSelectedAmountVariant}
                      placeholder={"Select amount"}
                      type="amount"
                    />
                    <CustomSelectDrawer
                      data={availableAmounts}
                      state={selectedAmountVariant?.slug}
                      setStateCallback={setSelectedAmountVariant}
                      placeholder={"Select amount"}
                      type="amount"
                    />
                  </>
                )}
                {!isGiftCardProduct && (
                  <>
                    <CustomSelect
                      // data={customSort(availableSizes)}
                      data={availableSizes}
                      state={selectedSizeVariant?.slug}
                      setStateCallback={setSelectedSizeVariant}
                      placeholder={"Select size"}
                      type="size"
                    />
                    <CustomSelectDrawer
                      // data={customSort(availableSizes)}
                      data={availableSizes}
                      state={selectedSizeVariant?.slug}
                      setStateCallback={setSelectedSizeVariant}
                      placeholder={"Select size"}
                      type="size"
                    />
                  </>
                )}
                {!isFrameProduct &&
                  !isPassepartoutProduct &&
                  !isGiftCardProduct &&
                  !isTransparentArtPrintProduct &&
                  !isWallObjectsProduct &&
                  !isLimitedEditionsProduct && (
                    <>
                      <CustomSelect
                        data={availablePasspartout}
                        state={selectedPassepartoutVariant?.slug}
                        setStateCallback={setSelectedPassepartoutVariant}
                        placeholder={"Add passepartout"}
                        type="passepartout"
                      />
                      <CustomSelectDrawer
                        data={availablePasspartout}
                        state={selectedPassepartoutVariant?.slug}
                        setStateCallback={setSelectedPassepartoutVariant}
                        placeholder={"Add passepartout"}
                        type="passepartout"
                      />
                      {/* <AnimatePresence mode="wait">
                    {!isEmpty(selectedPassepartoutVariant) &&
                      selectedPassepartoutVariant?.slug !==
                        "no-passepartout" && (
                        <motion.div
                          className="prod-card overflow-hidden"
                          key="prod-card-passepartout"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { height: "auto", opacity: 1 },
                            collapsed: { height: 0, opacity: 0 },
                          }}
                          transition={{ type: "tween" }}
                        >
                          <SelectProductCard
                            product={selectedPassepartoutVariant}
                            currency={currency}
                          />
                        </motion.div>
                      )}
                  </AnimatePresence> */}
                    </>
                  )}
                {!isFrameProduct &&
                  !isGiftCardProduct &&
                  !isTransparentArtPrintProduct &&
                  !isWallObjectsProduct &&
                  !isLimitedEditionsProduct && (
                    <>
                      <CustomSelect
                        data={availableFrames}
                        state={selectedFrameVariant?.slug}
                        setStateCallback={setSelectedFrameVariant}
                        placeholder={"Add frame"}
                        type="frame"
                      />
                      <CustomSelectDrawer
                        data={availableFrames}
                        state={selectedFrameVariant?.slug}
                        setStateCallback={setSelectedFrameVariant}
                        placeholder={"Add frame"}
                        type="frame"
                      />
                      {/* <AnimatePresence mode="wait">
                  {!isEmpty(selectedFrameVariant) &&
                    selectedFrameVariant?.slug !== "no-frame" && (
                      <motion.div
                        className="prod-card overflow-hidden"
                        key="prod-card-frame"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                          open: { height: "auto", opacity: 1 },
                          collapsed: { height: 0, opacity: 0 },
                        }}
                        transition={{ type: "tween" }}
                      >
                        <SelectProductCard
                          product={selectedFrameVariant}
                          currency={currency}
                        />
                      </motion.div>
                    )}
                </AnimatePresence> */}
                    </>
                  )}
              </div>
            )}
            {/* <Tooltip.Root
            key="frame-tooltip"
            delayDuration={200}
            open={showFrameTooltip}
            onOpenChange={setShowFrameTooltip}
          >
            <Tooltip.Trigger asChild>
              <button
                className="font-body-regular text-xs text-gray-light flex items-center gap-1 hover:text-black transition-colors duration-200 ease-in-out group mt-4"
                onClick={() => setShowFrameTooltip(true)}
              >
                Free framing service
                <FontAwesomeIcon
                  icon={["fal", "info-circle"]}
                  className="text-gray-light group-hover:text-black transition-colors duration-200 ease-in-out"
                />
              </button>
            </Tooltip.Trigger>
            <AnimatePresence mode="wait">
              {showFrameTooltip && (
                <Tooltip.Content
                  forceMount
                  align="start"
                  sideOffset={8}
                  asChild
                >
                  <motion.p
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{
                      opacity: 0,
                    }}
                    className="bg-black text-white font-display-regular font-normal text-xxs tracking-wider px-4 py-2 rounded-md max-w-[300px]"
                  >
                    Free framing service — If you buy a matching frame we will
                    frame the art print for you so it is ready to hang when you
                    receive it.
                  </motion.p>
                </Tooltip.Content>
              )}
            </AnimatePresence>
          </Tooltip.Root> */}
            <div className="relative mt-6 hidden">
              <motion.div
                className="font-body-regular text-sm text-black overflow-hidden [&>*:last-child]:mb-0"
                initial="collapsed"
                animate={showDescription ? "open" : "collapsed"}
                variants={{
                  open: { height: "auto" },
                  collapsed: { height: sm ? 72 : 52 },
                }}
                transition={{ type: "tween" }}
                dangerouslySetInnerHTML={{
                  __html:
                    singleProductDescription +
                    (selectedSizeVariant &&
                    selectedSizeVariant?.variationDescription
                      ? selectedSizeVariant.variationDescription
                      : ""),
                }}
              />
              <AnimatePresence>
                {!showDescription && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: "tween" }}
                    className="absolute top-0 left-0 w-full h-full z-50 bg-gradient-to-t from-white"
                  />
                )}
              </AnimatePresence>
            </div>
            <button
              className="font-display-bold text-xs tracking-[0.54px] text-black uppercase mt-2 underline underline-offset-4 hidden"
              onClick={() => setShowDescription((prev) => !prev)}
            >
              {`${showDescription ? "Close" : "Read more"}`}
            </button>
            {totalPrice > 0 && (
              <Price priceForSelectedSizeVariant={totalPrice} />
            )}
            <AddToBagButton
              hasSize={!isEmpty(selectedSizeVariant) ? true : false}
              hasAmount={!isEmpty(selectedAmountVariant) ? true : false}
              isGiftCardProduct={isGiftCardProduct}
              isPassepartoutProduct={isPassepartoutProduct}
              isFrameProduct={isFrameProduct}
              selectedAmountVariant={selectedAmountVariant}
              selectedSizeVariant={selectedSizeVariant}
              selectedPassepartoutVariant={selectedPassepartoutVariant}
              selectedFrameVariant={selectedFrameVariant}
              isOutOfStock={isOutOfStock}
            />
            {!isGiftCardProduct && (
              <div className="hidden sm:block w-full">
                <Info />
              </div>
            )}
          </div>
        </motion.div>
        {!isGiftCardProduct && (
          <div className="block sm:hidden">
            <Info />
          </div>
        )}
        {!isGiftCardProduct &&
          !isWallObjectsProduct &&
          !isLimitedEditionsProduct && (
            <div className="mt-12 flex sm:hidden flex-col gap-12">
              {artWallPushSections?.map((artWallPushSection, index) => (
                <div key={index}>
                  <h3 className="font-body-regular text-lg text-black whitespace-pre-line mt-0 mb-1">
                    {artWallPushSection?.title}
                  </h3>
                  <div
                    className="text-[#787878] font-body-regular text-sm [&>*]:m-0"
                    dangerouslySetInnerHTML={{
                      __html: artWallPushSection?.description,
                    }}
                  />
                  <a
                    href={
                      artWallPushSection?.["cta_button"]?.["cta_custom_link"] ||
                      artWallPushSection?.["cta_button"]?.["cta_link"]
                    }
                    className="mt-7 inline-block font-display-bold text-xs tracking-[0.54px] text-black uppercase underline underline-offset-4"
                  >
                    {artWallPushSection?.["cta_button"]?.["cta_name"]}
                  </a>
                </div>
              ))}
            </div>
          )}
        {artistPost?.post_title && (
          <ArtistSection
            artist={artistPost}
            artistImage={artistPostImage}
            artistSecondaryImage={artistSecondaryImage}
            artistLink={artistPostLink}
          />
        )}
        {selectedForYouProducts &&
          selectedForYouProducts.length > 0 &&
          !isOutOfStock && (
            <>
              <p className="mt-12 mb-4 font-body-regular-italic text-2xl leading-[24px] tracking-[0.34px] text-black">
                {isGiftCardProduct
                  ? "Gift our Entire Collection"
                  : "Selected for you"}
              </p>
              <SelectedForYouSlider
                selectedForYouProducts={selectedForYouProducts}
              />
              <div className="flex justify-center mt-8">
                <a
                  href={determineSelectedForYouProductsLink()}
                  className="font-normal font-display-bold text-sm tracking-wider text-white uppercase h-11 flex justify-center items-center transition-colors duration-200 ease-in-out bg-black w-full md:max-w-[33.333333%]"
                >
                  See full collection
                </a>
              </div>
            </>
          )}
        {/* {!isGiftCardProduct &&
          selectedForYouFrameProducts &&
          selectedForYouFrameProducts.length > 0 &&
          !isOutOfStock && (
            <>
              <p className="mt-12 mb-4 font-body-regular-italic text-2xl leading-[24px] tracking-[0.34px] text-black">
                Explore our frames
              </p>
              <SelectedForYouSlider
                selectedForYouProducts={selectedForYouFrameProducts}
              />
              <div className="flex justify-center mt-8">
                <a
                  href="/frames"
                  className="font-normal font-display-bold text-sm tracking-wider text-white uppercase h-11 flex justify-center items-center transition-colors duration-200 ease-in-out bg-black w-full md:max-w-[33.333333%]"
                >
                  See full collection
                </a>
              </div>
            </>
          )} */}
        {youMightLikeProducts &&
          youMightLikeProducts.length > 0 &&
          isOutOfStock && (
            <>
              <p className="mt-12 mb-4 font-body-regular-italic text-2xl leading-[24px] tracking-[0.34px] text-black">
                You might like
              </p>
              <SelectedForYouSlider
                selectedForYouProducts={youMightLikeProducts}
              />
              <div className="flex justify-center mt-8">
                <a
                  href="/artprints"
                  className="font-normal font-display-bold text-sm tracking-wider text-white uppercase h-11 flex justify-center items-center transition-colors duration-200 ease-in-out bg-black w-full md:max-w-[33.333333%]"
                >
                  See full collection
                </a>
              </div>
            </>
          )}
      </div>
      <SizeGuideSidebar />
      <ProductInfoSidebar
        selectedPassepartoutVariant={selectedPassepartoutVariant}
        selectedFrameVariant={selectedFrameVariant}
      />
    </>
  );
};

export default SingleProductComponent;
