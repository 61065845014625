"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { push } from 'react-router-redux';
import { push } from "connected-react-router";

import classNames from "classnames";
import { has, isEmpty } from "lodash";
import { twoDigits, urlStrToJSON } from "./../lib/parse";

// import { Lazy } from 'react-lazy';
import ImageComponent from "./ImageComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import updateFavourites from "../actions/favourites/updateFavourites";
import updateFavourite from "../actions/favourites/updateFavourite";
import setLastHasOverlay from "../actions/favourites/setLastHasOverlay";
import setProductClick from "../actions/products/setProductClick";
import _ from "lodash";
import outsideClick from "../lib/clickOutside";
import { warpFragmentRefresh } from "../modules/ajaxAddToCart";
import mountMiniCart from "../modules/miniCart";
import { FAVOURITES_ACTIVE } from "../lib/globals";
import setBuyError from "../actions/favourites/setBuyError";
import setBuyErrorSingle from "./../actions/favourites/setBuyErrorSingle";
import { Link } from "react-router-dom";
import { compareSizesFavourites } from "../lib/helpers";
// import useMediaQuery from '../hooks/useMediaQuery';

class ProductComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      productImage: props.product.image,
      productMobileImage: props.product.mobileImage,
      hoverImgPreloaded: false,
      hideSize: false,
      hasOverlay: false,
      hasSizeError: false,
      IdFromOptionsDropdown: "",
      currentSize: "selectSize",
      sizeNameFromOptionsDropdown: "",
      sizeSlugFromOptionsDropdown: "",
      priceFromOptionsDropdown: "",
      addingToCartText: "",
      addToCartError: false,
      matches: window.matchMedia("(min-width: 640px)").matches,
    };

    this.getFavouriteMatch = this.getFavouriteMatch.bind(this);
    this.getFavouriteVariationSize = this.getFavouriteVariationSize.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFavourite = this.handleFavourite.bind(this);
    this.throwSizeError = this.throwSizeError.bind(this);
    this.resolveSizeError = this.resolveSizeError.bind(this);
    this.isVariableProduct = this.isVariableProduct.bind(this);
    // this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this)
    this.handleAjaxAddToCart = this.handleAjaxAddToCart.bind(this);
    this.isFavouritesRoute = this.isFavouritesRoute.bind(this);
    this.handleSelectSizeChange = this.handleSelectSizeChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (FAVOURITES_ACTIVE) {
      if (prevProps.buyError !== this.props.buyError) {
        const favouriteMatch = this.getFavouriteMatch();

        if (this.isVariableProduct() && !favouriteMatch.variationId) {
          this.throwSizeError();
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.product.id !== this.props.product.id) {
      this.setState({
        productImage: nextProps.product.image,
        productMobileImage: nextProps.product.mobileImage,
        hoverImgPreloaded: false,
      });
    }
  }

  throwSizeError() {
    this.setState({
      hasSizeError: true,
    });

    // const { product } = this.props
    // this.props.actions.setLastHasOverlay(product.id)
  }

  resolveSizeError() {
    this.setState({
      hasSizeError: false,
    });
  }

  handleSelectSizeChange(e) {
    console.log("handleSelectSizeChange runs");
    const { product, favourites, buyError, pathname, search } = this.props;
    const selectedProduct =
      e.target.value !== "selectSize"
        ? JSON.parse(e.target.value)
        : e.target.value;

    if (selectedProduct !== "selectSize") {
      const selectedProductSlug = selectedProduct.slug;

      this.setState({
        sizeNameFromOptionsDropdown: selectedProduct.name,
        sizeSlugFromOptionsDropdown: selectedProductSlug,
        currentSize: selectedProduct,
        addToCartError: false,
      });

      const variations = product.variations;
      const matchedVariation = _.find(
        variations,
        (v) => v.sizeSlug === selectedProductSlug
      );

      if (!!matchedVariation) {
        this.setState({
          priceFromOptionsDropdown: matchedVariation.price,
          IdFromOptionsDropdown: matchedVariation.id,
        });

        this.props.actions.updateFavourite(
          {
            id: product.id,
            isVariable: this.isVariableProduct(),
            variationId: matchedVariation.id,
            variationSize: selectedProduct.name,
            variationSizeSlug: matchedVariation.sizeSlug,
          },
          favourites,
          search
        );
      }

      if (buyError) {
        this.resolveSizeError();
      }
    }
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 640px)").addEventListener("change", handler);

    if (FAVOURITES_ACTIVE) {
      const { product, favouritesFromHash, search } = this.props;

      const searchParams = urlStrToJSON(search);
      if (has(searchParams, "h")) {
        const currentFavouritesFromHash = _.find(
          favouritesFromHash,
          (ffh) => ffh.id === product.id
        );

        this.setState({
          sizeNameFromOptionsDropdown: currentFavouritesFromHash.variationSize,
          sizeSlugFromOptionsDropdown:
            currentFavouritesFromHash.variationSizeSlug,
        });

        const variations = product.variations;
        const matchedVariation = _.find(
          variations,
          (v) => v.sizeSlug === currentFavouritesFromHash.variationSizeSlug
        );

        if (!!matchedVariation) {
          this.setState({
            priceFromOptionsDropdown: matchedVariation.price,
            IdFromOptionsDropdown: matchedVariation.id,
          });
        }
      }

      window.addEventListener("mousedown", this.onClickOutsideHandler);
    }
  }

  componentWillUnmount() {
    if (FAVOURITES_ACTIVE) {
      window.removeEventListener("mousedown", this.onClickOutsideHandler);
    }
  }

  handleClick(e) {
    let top = window.pageYOffset || document.documentElement.scrollTop;
    if (top > 0) {
      e.preventDefault();
      let targetHref = e.currentTarget.href;
      // console.log('save scroll pos!', top, targetHref);
      let { pathname, page } = this.props;
      let search = window.location.search;
      // Page is set to next page for load more smoothness - subtract to match current page if nescesarry
      if (page > 1) {
        page -= 1;
      }
      // console.log('url object before push', pathname, search, top, page)
      let currentHref =
        search.indexOf("?") > -1
          ? `${pathname}${search}&pos=${top}&page=${page}`
          : `${pathname}?pos=${top}&page=${page}`;
      // console.log('the current ref', currentHref)

      // this.props.dispatch(push(currentHref));
      this.props.actions.setProductClick(true);
      this.props.actions.push(currentHref);
      // window.history.pushState({}, '', currentHref)
      window.location.href = targetHref;
      // console.log('current href with pos', currentHref);
    }
  }

  // onClickOutsideHandler(e) {
  //   const { buyError } = this.props
  //   const productWrapperRef = this.refs.productWrapperRef

  //   if (productWrapperRef && !productWrapperRef.contains(e.target) && !buyError) {
  //     this.closeOptionsOverlay()
  //   }
  // }

  handleFavourite() {
    const { product, favourites, search } = this.props;
    const {
      IdFromOptionsDropdown,
      sizeNameFromOptionsDropdown,
      sizeSlugFromOptionsDropdown,
    } = this.state;

    this.props.actions.updateFavourites(
      {
        id: product.id,
        isVariable: this.isVariableProduct(),
        variationId: !!IdFromOptionsDropdown ? IdFromOptionsDropdown : "",
        variationSize: !!sizeNameFromOptionsDropdown
          ? sizeNameFromOptionsDropdown
          : "",
        variationSizeSlug: !!sizeSlugFromOptionsDropdown
          ? sizeSlugFromOptionsDropdown
          : "",
      },
      favourites,
      search
    );
  }

  getFavouriteMatch() {
    const { favourites, product } = this.props;

    const match = favourites
      ? favourites.find((f) => parseInt(f.id) === parseInt(product.id))
      : false;

    return match;
  }

  getFavouriteVariationSize() {
    const favouriteMatch = this.getFavouriteMatch();

    console.log("🌟 FAVOURITES -*( favourites match )*-", favouriteMatch);

    const variationSize =
      favouriteMatch && favouriteMatch.variationSize
        ? favouriteMatch.variationSize
        : false;

    console.log("🌟 FAVOURITES -*( variation size )*-", variationSize);

    return variationSize;
  }

  handleAjaxAddToCart(e) {
    const { sizeNameFromOptionsDropdown } = this.state;
    console.log(
      "🌟 FAVOURITES -*( size name from options drop down )*-",
      sizeNameFromOptionsDropdown
    );
    if (
      this.isVariableProduct() &&
      !this.getFavouriteVariationSize() &&
      !sizeNameFromOptionsDropdown
    ) {
      console.log(
        "🌟 FAVOURITES -*( no CAN do... )*-",
        this.isVariableProduct(),
        !this.getFavouriteVariationSize(),
        !sizeNameFromOptionsDropdown
      );
      this.setState({
        addToCartError: true,
      });
      this.props.actions.setBuyErrorSingle(true);

      return;
    }

    const clickMaster = new outsideClick();
    const { product } = this.props;
    const { IdFromOptionsDropdown, sizeSlugFromOptionsDropdown } = this.state;

    const favouriteMatch = this.getFavouriteMatch();
    const variationId =
      IdFromOptionsDropdown || (favouriteMatch && favouriteMatch.variationId);
    const sizeSlug =
      sizeSlugFromOptionsDropdown ||
      (favouriteMatch && favouriteMatch.variationSizeSlug);
    const currentUrl = window.location;

    this.setState({
      addingToCartText: "Adding",
    });

    const productArray = [
      `add-to-cart=${product.id}`,
      ...(this.isVariableProduct() ? [`product_id=${product.id}`] : []),
      ...(this.isVariableProduct() ? [`variation_id=${variationId}`] : []),
      ...(this.isVariableProduct() ? [`attribute_pa_size=${sizeSlug}`] : []),
    ];

    const productArraySerialized = productArray.join("&");

    jQuery.post(
      currentUrl,
      productArraySerialized + "&_wp_http_referer=" + currentUrl,
      (result) => {
        const cart_dropdown = jQuery("#mini-cart", result);
        const cart_icon = jQuery("#cart-contents", result);
        const woocommerce_success = jQuery(".woocommerce-message", result);
        const woocommerce_error = jQuery(".woocommerce-error", result);
        let hasError;

        if (woocommerce_error.length > 0) {
          hasError = true;
        } else if (woocommerce_success.length > 0) {
          hasError = false;
        }

        if (hasError) {
          jQuery(".type-product").eq(0).before(woocommerce_error);
        } else {
          const errorContainer = jQuery(".woocommerce-error");

          if (errorContainer.length > 0) {
            jQuery(errorContainer).remove();
          }

          // jQuery('#mini-cart').replaceWith(cart_dropdown)
          const hiddenMinicart = document.getElementById("mini-cart-hidden");
          const hiddenMinicartClone = hiddenMinicart.cloneNode(true);
          hiddenMinicartClone.setAttribute("id", "mini-cart");
          // jQuery('#cart-contents').replaceWith(cart_icon)
        }

        jQuery.ajax(warpFragmentRefresh);
        mountMiniCart(clickMaster);

        this.setState({
          addingToCartText: "Added",
        });

        setTimeout(() => {
          this.setState({
            addingToCartText: "",
          });
        }, 1500);
      }
    );
  }

  handleImageLoaded(isLoaded) {
    const { product } = this.props;
    let hoverSrc = product.hoverImg;
    let { hoverImgPreloaded } = this.state;

    if (hoverSrc && !hoverImgPreloaded) {
      let hoverImg = new Image();
      hoverImg.src = hoverSrc;
      hoverImgPreloaded = true;
    }

    this.setState({
      isLoaded: isLoaded,
      hoverImgPreloaded: hoverImgPreloaded,
    });
  }

  handleMouseOver(isHovering, e) {
    const { product } = this.props;
    const { hideSize } = this.state;
    let image = product.image;

    if (isHovering) {
      image = product.hoverImg || product.image;
    }

    this.setState({
      productImage: image,
      hideSize: !hideSize,
    });
  }

  isVariableProduct() {
    const { product } = this.props;

    const boolean =
      !_.isEmpty(product.size) && product.size.length > 1 ? true : false;

    return boolean;
  }

  isFavouritesRoute() {
    const { pathname } = this.props;
    const subString = "favourites";

    const containsSubString = pathname.includes(subString);

    return containsSubString ? true : false;
  }

  render() {
    // const isAboveMobile = useMediaQuery('(min-width: 640px)')

    let { product, perRow, favourites, index, buyError, favouritesFromHash } =
      this.props;
    let artistRaw,
      artist,
      size,
      price,
      gridClasses,
      label,
      stock,
      pinText,
      colorSlug,
      isFrame,
      isPassepartout,
      favouriteSize,
      name;
    const {
      productImage,
      productMobileImage,
      hasOverlay,
      sizeNameFromOptionsDropdown,
      sizeSlugFromOptionsDropdown,
      IdFromOptionsDropdown,
      priceFromOptionsDropdown,
      isLoaded,
      hideSize,
      addingToCartText,
      hasSizeError,
      addToCartError,
    } = this.state;
    artistRaw =
      product.artist.length > 0 ? product.artist[0].name : "Unknown artist";
    isFrame = false;
    isPassepartout = false;

    name = { __html: product?.post_title };

    const isLimitedEditionsProduct = product?.categories?.find(
      (category) => category?.slug === "limited-editions"
    );
    const isWallObjectsProduct = product?.categories?.find(
      (category) => category?.slug === "wall-objects"
    );

    // console.log("isLimitedEditionsProduct", isLimitedEditionsProduct);
    // console.log("isWallObjectsProduct", isWallObjectsProduct);
    // console.log("product", product);

    // console.log('favouritesFromHash', favouritesFromHash);
    // console.log('MATCH', this.getFavouriteMatch());

    for (let i = 0; product.categories.length > i; i++) {
      let c = product.categories[i];
      if (c.slug === "frames") {
        isFrame = true;
        break;
      }
      if (c.slug === "passepartout") {
        isPassepartout = true;
        break;
      }
    }

    if (isFrame || isPassepartout) {
      artistRaw = product.post_title;
    }

    artist = { __html: artistRaw };

    colorSlug = "";

    if (!isEmpty(product.color)) {
      colorSlug = `${product.color[0].slug}`;
    }

    // console.log(this.isVariableProduct() && this.getFavouriteMatch() && product.variations && _.find(product.variations, v => v.id === this.getFavouriteMatch().variationId).price);
    price = !!sizeNameFromOptionsDropdown
      ? twoDigits(parseInt(priceFromOptionsDropdown))
      : this.isFavouritesRoute() &&
        this.isVariableProduct() &&
        this.getFavouriteMatch() &&
        product.variations &&
        this.getFavouriteMatch().variationId
      ? !_.isEmpty(
          _.find(
            product.variations,
            (v) => v.id === parseInt(this.getFavouriteMatch().variationId)
          )
        )
        ? twoDigits(
            parseInt(
              _.find(
                product.variations,
                (v) => v.id === parseInt(this.getFavouriteMatch().variationId)
              ).price
            )
          )
        : false
      : product.price
      ? twoDigits(parseInt(product.price))
      : null;
    // console.log('sizeNameFromOptionsDropdown', sizeNameFromOptionsDropdown);
    // console.log('product', product);
    // console.log('this.getFavouriteMatch()', this.getFavouriteMatch());
    // console.log('_.find(product.variations, v => v.id === this.getFavouriteMatch().variationId', _.find(product.variations, v => v.id === parseInt(this.getFavouriteMatch().variationId)))
    // price = sizeNameFromOptionsDropdown ? twoDigits(parseInt(priceFromOptionsDropdown)) : product.variations && this.isVariableProduct() && this.getFavouriteMatch() ? twoDigits(parseInt(_.find(product.variations, v => v.id === this.getFavouriteMatch().variationId).price)) : product.price ? twoDigits(parseInt(product.price)) : null
    // price = ''

    product.stockStatus !== "outofstock"
      ? ((label = "EUR " + price), (stock = "instock"))
      : ((label =
          isLimitedEditionsProduct || isWallObjectsProduct
            ? "SOLD OUT"
            : "Out of stock"),
        (stock = "outofstock"));

    if (product.size.length > 0) {
      const favouriteMatch = !!favourites
        ? favourites.find((f) => parseInt(f.id) === parseInt(product.id))
        : false;
      const variationId = favouriteMatch ? favouriteMatch.variationId : false;
      // console.log('variationId', variationId);
      const variationSize = favouriteMatch
        ? favouriteMatch.variationSize
        : false;
      // console.log('favouriteMatch', favouriteMatch);
      // size = !!variationId ? variationSize : sizeNameFromOptionsDropdown ? sizeNameFromOptionsDropdown : this.isVariableProduct() ? "Multiple sizes" : product.size[0].name;
      size = this.isVariableProduct() ? "Multiple sizes" : product.size[0].name;
      favouriteSize = !!variationId
        ? variationSize
        : sizeNameFromOptionsDropdown
        ? sizeNameFromOptionsDropdown
        : "Select size";
    }

    pinText =
      product.post_title +
      " by " +
      artistRaw +
      " | Poster from theposterclub.com";

    gridClasses = classNames({
      "grid-item": true,
      "grid-item--per-row-2": perRow === 2,
      "grid-item--per-row-3": perRow === 3,
      "grid-item--per-row-4": perRow === 4,
    });

    const src = this.state.matches ? productImage : productMobileImage;

    // console.log("this.state.currentSize", this.state.currentSize);

    // console.log("product.size", product.size);

    return FAVOURITES_ACTIVE ? (
      // <li id={product.id} data-color={colorSlug} className={gridClasses} onMouseEnter={this.handleMouseOver.bind(this, true)} onMouseLeave={this.handleMouseOver.bind(this, false)} ref='productWrapperRef'>
      <li id={product.id} data-color={colorSlug} className={gridClasses}>
        <div
          className={classNames("grid-item-inner", {
            loaded: isLoaded,
          })}
        >
          {/* <Lazy component="div" cushion={1000}> */}
          <div>
            <div
              className="grid-item-image-wrapper"
              onMouseEnter={this.handleMouseOver.bind(this, true)}
              onMouseLeave={this.handleMouseOver.bind(this, false)}
            >
              <a
                href={product.url}
                title={pinText}
                aria-label={pinText}
                onClick={this.handleClick}
                className="relative"
              >
                {(isLimitedEditionsProduct || isWallObjectsProduct) &&
                  product.stockStatus === "outofstock" && (
                    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 z-10" />
                  )}
                {(isLimitedEditionsProduct || isWallObjectsProduct) &&
                  product.stockStatus === "outofstock" && (
                    <span className="hidden sm:inline-block absolute right-0 top-0 m-4 z-10 bg-white text-gray-text pt-1 pb-[2px] px-2 uppercase text-sm tracking-[0.06em] font-display-regular">
                      Sold out
                    </span>
                  )}
                {product.onSale ? <span className="onsale">Sale</span> : null}
                <ImageComponent
                  src={src}
                  // mobileSrc={productMobileImage}
                  classes={["lazy-img", hasOverlay && "has-overlay"]}
                  alt={pinText}
                  width={450}
                  height={500}
                  loadedCallback={this.handleImageLoaded}
                />
              </a>
            </div>
            <div className="grid-item-footer">
              <div className="grid-item-footer-inner">
                <div className="grid-item-footer-inner__flex-wrapper">
                  <a
                    href={product.url}
                    className="product-title"
                    dangerouslySetInnerHTML={
                      isLimitedEditionsProduct || isWallObjectsProduct
                        ? name
                        : artist
                    }
                  />
                  {!this.isFavouritesRoute() && (
                    <div className="icon-wrapper">
                      <FontAwesomeIcon
                        className={classNames("icon-outline", {
                          "is-favourite": this.getFavouriteMatch(),
                        })}
                        icon={["fal", "heart"]}
                        onClick={this.handleFavourite}
                      />
                      <FontAwesomeIcon
                        className={classNames("icon-fill", {
                          "is-favourite": this.getFavouriteMatch(),
                        })}
                        icon={["fas", "heart"]}
                      />
                    </div>
                  )}
                </div>
                <div className="grid-item-footer-inner__flex-wrapper">
                  <p className="p-amount">{label}</p>
                  <div
                    className="grid-item-footer-inner__position-wrapper"
                    data-stock={stock}
                  >
                    {!this.isFavouritesRoute() ? (
                      <p className={classNames("product-size")}>{size}</p>
                    ) : (
                      <div className="icon-wrapper">
                        <FontAwesomeIcon
                          className={classNames("icon-outline", {
                            "is-favourite": this.getFavouriteMatch(),
                          })}
                          icon={["fal", "heart"]}
                          onClick={this.handleFavourite}
                        />
                        <FontAwesomeIcon
                          className={classNames("icon-fill", {
                            "is-favourite": this.getFavouriteMatch(),
                          })}
                          icon={["fas", "heart"]}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* </Lazy> */}
          </div>
        </div>
        {this.isFavouritesRoute() && (
          <div className="favourites-action-bar">
            {this.isVariableProduct() ? (
              <div className="product-size-wrapper product-size-wrapper--dropdown">
                <div className="product-variation-size">
                  <div className="product-variation-size-label-wrapper">
                    <p
                      className={classNames("product-variation-size-label", {
                        "buy-error": hasSizeError ? true : false,
                      })}
                      style={{
                        fontSize:
                          favouriteSize.length >= 11 &&
                          !favouriteSize.toLowerCase().includes("select size")
                            ? "10px"
                            : "13px",
                      }}
                    >
                      {favouriteSize}
                    </p>
                    <FontAwesomeIcon
                      className={classNames("product-variation-size-chevron", {
                        "buy-error": hasSizeError ? true : false,
                      })}
                      icon={["fal", "chevron-down"]}
                    />
                  </div>
                  <select
                    id="size-dropdown"
                    value={JSON.stringify(this.state.currentSize)}
                    className="product-variation-size-dropdown"
                    onChange={this.handleSelectSizeChange}
                  >
                    <option value={JSON.stringify("selectSize")} disabled>
                      Select size
                    </option>
                    {product.size.sort(compareSizesFavourites).map((p, i) => {
                      return (
                        <option key={i} value={JSON.stringify(p)}>
                          {p.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : (
              <div className="product-size-wrapper">
                <p
                  className={classNames("product-size")}
                  style={{
                    fontSize: size.length >= 15 ? "12px" : "13px",
                    lineHeight: size.length >= 15 ? "13px" : "1",
                  }}
                >
                  {size}
                </p>
              </div>
            )}
            <div className="favourites-action-bar__add-to-bag">
              <span
                onClick={this.handleAjaxAddToCart}
                className={classNames(
                  "product-action product-action-add-to-cart",
                  { "add-to-cart-error": addToCartError ? true : false }
                )}
              >
                {!!addingToCartText ? addingToCartText : "Add to bag"}
              </span>
            </div>
          </div>
        )}
      </li>
    ) : (
      // <li id={product.id} data-color={colorSlug} className={gridClasses} onMouseEnter={this.handleMouseOver.bind(this, true)} onMouseLeave={this.handleMouseOver.bind(this, false)}>
      <li id={product.id} data-color={colorSlug} className={gridClasses}>
        <div className="grid-item-inner">
          {/* <Lazy nodeName="a" aria-label="The Poster Club | Pretty link" onClick={this.handleClick} href={product.url} cushion={1000}> */}
          <Link
            to={product.url}
            aria-label="The Poster Club | Pretty link"
            onClick={this.handleClick}
          >
            {product.onSale ? <span className="onsale">Sale</span> : null}
            <ImageComponent
              src={src}
              // mobileSrc={productMobileImage}
              classes={["lazy-img"]}
              alt={pinText}
              title={pinText}
              width={450}
              height={500}
              loadedCallback={this.handleImageLoaded}
              onMouseEnter={this.handleMouseOver.bind(this, true)}
              onMouseLeave={this.handleMouseOver.bind(this, false)}
            />
            <div className="grid-item-footer">
              <div className="grid-item-footer-inner">
                <h3 dangerouslySetInnerHTML={artist} />
                <span data-stock={stock}>
                  <span className="p-amount">{`${label} `}</span>
                </span>
                <span className="product-size">{size}</span>
              </div>
            </div>
            {/* </Lazy> */}
          </Link>
        </div>
      </li>
    );
  }
}

ProductComponent.displayName = "ProductComponent";

ProductComponent.propTypes = {
  product: PropTypes.shape({
    artist: PropTypes.array,
    color: PropTypes.array,
    image: PropTypes.string,
    inStock: PropTypes.bool,
    onSale: PropTypes.bool,
    popularity: PropTypes.number,
    poster_type: PropTypes.array,
    price: PropTypes.string,
    price_range: PropTypes.array,
    sale_price: PropTypes.string,
    size: PropTypes.array,
    stockStatus: PropTypes.string,
    timestamp: PropTypes.number,
    url: PropTypes.string,
  }),
};
ProductComponent.defaultProps = {
  product: {
    artist: [],
    color: [],
    image: "",
    inStock: false,
    onSale: false,
    popularity: 0,
    poster_type: [],
    price: "",
    price_range: [],
    sale_price: "",
    size: [],
    stockStatus: "instock",
    timestamp: 0,
    url: "",
  },
};

let mapStateToProps = (state) => {
  const props = {
    favourites: state.favourites.favourites,
    buyError: state.favourites.buyError,
    lastHasOverlay: state.favourites.lastHasOverlay,
    page: state.products.page,
    pathname: state.router.location.pathname,
    search: state.router.location.search,
  };

  return props;
};

let mapDispatchToProps = (dispatch) => {
  const actions = {
    updateFavourites,
    updateFavourite,
    setLastHasOverlay,
    setProductClick,
    setBuyError,
    setBuyErrorSingle,
    push,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };

  return actionMap;
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductComponent);
