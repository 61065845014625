"use strict";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import _, { get, has } from "lodash";
import { throttle } from "lodash";
import {
  CAT_UNCATEGORIZED,
  CAT_POSTERS_PRINTS,
  CAT_TRANSPARENT_FRAMES,
  CAT_FRAMES_OTHER,
  CAT_FRAMES_TPC,
  CAT_FRAMES,
  CAT_PASSEPAROUT,
  CAT_OUTLET,
  CAT_ART_CARDS,
  CAT_FRAME_PAINT,
  CAT_ORIGINAL_ART,
  CAT_FRAMES_ALUMINIUM,
  CAT_FRAMES_MOEBE,
  CAT_FRAMES_COLOURED,
  CAT_FRAMES_OAK,
} from "./../lib/globals";

import Product from "./ProductComponent";
import SearchTerm from "./SearchTermComponent";
import NoItems from "./NoItemsComponent";
import Loading from "./LoadingComponent";
import { urlStrToJSON } from "../lib/parse";

class ProductsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frontGridExceptions: [
        CAT_FRAMES,
        CAT_PASSEPAROUT,
        CAT_FRAMES_TPC,
        CAT_FRAMES_OTHER,
        CAT_TRANSPARENT_FRAMES,
        CAT_OUTLET,
        CAT_ART_CARDS,
        CAT_FRAME_PAINT,
        CAT_ORIGINAL_ART,
        CAT_FRAMES_ALUMINIUM,
        CAT_FRAMES_MOEBE,
        CAT_FRAMES_COLOURED,
        CAT_FRAMES_OAK,
      ],
      perRowValues: [2, 3, 4],
      perRow: 3,
      hidePerRow: true,
    };

    this.perRowHandler = this.perRowHandler.bind(this);
    this.onScrollHandler = this.onScrollHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener(
      "scroll",
      throttle(this.onScrollHandler, 200, true)
    );
  }

  perRowHandler(perRowParam) {
    this.setState({
      perRow: perRowParam,
    });
  }

  onScrollHandler() {
    let y = window.scrollY;

    if (y >= 200) {
      this.setState({
        hidePerRow: false,
      });
    } else {
      this.setState({
        hidePerRow: true,
      });
    }
  }

  render() {
    let { perRow } = this.state;
    let {
      products,
      isFrames,
      isCatPage,
      isSingleProduct,
      productsOrFrames,
      isArtistOrCategory,
      favourites,
      pathname,
      search,
      isArtWall,
      is404,
      isCampaignPage,
    } = this.props;
    let currItems = products.items;
    let favouritesFromHash = products.favouritesFromHash;

    const searchParams = urlStrToJSON(search);
    // Filter favourites to remove recently removed favourites without reload
    if (pathname.includes("favourites")) {
      // Check for hash in url
      if (!has(searchParams, "h")) {
        const favouriteIds = favourites.map((f) => f.id);
        currItems = currItems.filter((p) => favouriteIds.includes(p.id));
      }
    }

    // console.log(noResult)
    // console.log('the current of items', currItems)
    // let currItems = !products.currSearch ? products.currItems.filter(product => product.stockStatus == 'instock') : products.currItems;

    // let perRowWrapperClasses = classNames({
    // 	'per-row-wrapper': true,
    // 	'per-row-wrapper--hide': this.state.hidePerRow
    // });

    // console.log(this.props.favourites);
    // console.log('collection hit', routing.locationBeforeTransitions.pathname.indexOf('collection') === -1);
    // console.log("im a grid", is404);

    // console.log({ products });
    return (
      <div
        {...(!isArtWall && { id: "grid" })}
        className={classNames({
          container:
            pathname !== "/favourites/" &&
            pathname.indexOf("collection") === -1 &&
            !isArtWall &&
            !is404,
          isCampaignPage,
        })}
      >
        {!products.isFetching &&
          !products.isFetchingMore &&
          !products.isFailed &&
          products.currSearch && (
            <SearchTerm
              searchTerm={products.currSearch}
              noResult={products.searchNoResults}
            />
          )}

        {/* <div className={perRowWrapperClasses}>
						<p>Show</p>
						<ul className="per-row-filter">
								{this.state.perRowValues.map((perRowValue, index) => {
										return <li key={index} className={perRowValue === this.state.perRow ? 'selected' : ''} onClick={() => this.perRowHandler(perRowValue)}>{perRowValue}</li>
								})}
						</ul>
				</div> */}

        <ul
          className={classNames(
            { "grid-component list-none": !isArtWall },
            { "flex flex-wrap -ml-3 -mt-3": isArtWall }
          )}
        >
          {!products.isFetching && !products.isFailed ? (
            currItems.length > 0 ? (
              currItems.map((product, i) => {
                return (
                  <Product
                    key={i}
                    product={product}
                    perRow={perRow}
                    favouritesFromHash={favouritesFromHash}
                    isArtWall={isArtWall}
                  />
                );
              })
            ) : (
              <NoItems isArtistOrCategory={isArtistOrCategory} />
            )
          ) : (
            <div style={{ gridColumn: "1 / -1" }}>
              <Loading type={`spinner`} />
            </div>
          )}
          {products.isFetchingMore && !_.isEmpty(favourites) && (
            <Loading type={`text`} />
          )}
        </ul>

        {products.searchNoResults && (
          <div className="related-grid related-grid--button no-results-button-container">
            <Link to="/artprints" className="shop-button no-results-button">
              See all art prints
            </Link>
          </div>
        )}

        {isArtistOrCategory && (
          <div className="related-grid related-grid--button container">
            <div className="mt-8">
              <a href="/artprints" className="shop-button">
                SEE ALL ART PRINTS
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ProductsComponent.displayName = "ProductsComponent";

ProductsComponent.propTypes = {
  products: PropTypes.shape({
    items: PropTypes.array,
    currItems: PropTypes.array,
    isFetching: PropTypes.bool,
    isFailed: PropTypes.bool,
  }),
  isFrames: PropTypes.bool,
  isSingleProduct: PropTypes.bool,
  productsOrFrames: PropTypes.shape({
    type: PropTypes.string,
    frames: PropTypes.array,
    product: PropTypes.array,
  }),
};

ProductsComponent.defaultProps = {
  products: {
    items: [],
    currItems: [],
    isFetching: false,
    isFailed: false,
  },
  isFrames: false,
  isSingleProduct: false,
  productsOrFrames: {
    type: "",
    frames: [],
    products: [],
  },
};

const mapStateToProps = (state) => {
  const props = {
    favourites: state.favourites.favourites,
    pathname: state.router.location.pathname,
    search: state.router.location.search,
  };

  return props;
};

export default connect(mapStateToProps, null)(ProductsComponent);
