import mountMiniCart from "./miniCart";
import { GA4AddToCart } from "../lib/tracking";
import _ from "lodash";
import isMobile from "./isMobile";

export const parseAddToCartResponse = ({ data }) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, "text/html");
  const msg = doc.querySelectorAll(".woocommerce-message");
  const info = doc.querySelectorAll(".woocommerce-info");
  const error = doc.querySelectorAll(".woocommerce-error");

  // console.log("the msgs from woo", {
  //   msg,
  //   info,
  //   error,
  // });

  return { msg, info, error };
};

/* global wc_add_to_cart_params jQuery */
const replaceAmountStuff = (html) => {
  // console.log('replacingmayns')
  return html.replace(" amount", "");
};

export const warpFragmentRefresh = {
  url: wc_add_to_cart_params.wc_ajax_url
    .toString()
    .replace("%%endpoint%%", "get_refreshed_fragments"),
  type: "POST",
  success: (data) => {
    // console.log('success!', data)
    if (data && data.fragments) {
      console.log("data and fragments", data.fragments);
      jQuery.each(data.fragments, function (key, value) {
        console.log("the KEY", key);
        if (value) {
          console.log("REPLACE!", jQuery(key), value);
          if (key !== "div.currencywidget_container") {
            value = replaceAmountStuff(value);
          }
          jQuery(key).replaceWith(value);
        }
      });

      jQuery(document.body).trigger("wc_fragments_refreshed");
    }
  },
};

export const triggerFragmentRefresh = () => {
  jQuery.ajax(warpFragmentRefresh);
};

const addToCartBtnState = (isLoading) => {
  let btns = document.querySelectorAll('button[type="submit"]');

  if (
    document.body.classList.contains("single-art-walls") ||
    document.body.classList.contains("single-perfect-pair")
  ) {
    btns = document.querySelectorAll(".buy-button");
  }

  for (let i = 0; btns.length > i; i++) {
    let btn = btns[i];
    if (isLoading) {
      // let loadingContainer = document.createElement('div')
      // let loadingInner = document.createElement('div')
      // loadingContainer.className = 'loading-component'
      // loadingInner.className = 'loading-component__loader loading-component__loader--btn'

      // loadingContainer.appendChild(loadingInner)
      if (!btn.dataset.label) {
        btn.dataset.label = btn.textContent;
      }
      btn.disabled = true;
      btn.textContent = "Adding";
      // btn.appendChild(loadingContainer)
    } else {
      // let loadingContainer = document.querySelector('.loading-component')
      // btn.removeChild(loadingContainer)
      btn.disabled = false;
      btn.textContent = "Added";
      setTimeout(() => {
        btn.textContent = btn.dataset.label;
      }, 1500);
    }
  }
};

export const addToCartSubmitCb = (e) => {
  // console.log("YOLO", e.data.clickMaster);
  e.preventDefault();

  const variationIdHiddenInput = document.querySelector(
    'input[name="variation_id"]'
  );

  if (variationIdHiddenInput && !variationIdHiddenInput.value) {
    let btns = document.querySelectorAll('button[type="submit"]');
    // console.log("btns", btns);
    for (let i = 0; btns.length > i; i++) {
      let btn = btns[i];

      if (!btn.dataset.label) {
        btn.dataset.label = btn.textContent;
      }

      btn.textContent = "Please select a size";
      btn.classList.add("error");

      jQuery("html, body").animate(
        {
          scrollTop: jQuery(".variations").offset().top - 80,
        },
        2000
      );

      setTimeout(() => {
        btn.textContent = btn.dataset.label;
        btn.classList.remove("error");
      }, 2500);
    }

    return;
  }

  let clickMaster = e.data.clickMaster;
  // console.log("clickMaster", clickMaster);

  addToCartBtnState(true);

  let product_url = window.location;
  let form = document.querySelector(".product-type-variable")
    ? jQuery(".variations_form.cart")
    : jQuery(e.currentTarget);
  // console.log("form", form);

  let button = e.currentTarget;
  let postData = form.serialize();

  const chunkArray = (array, chunkSize) => {
    let result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }

    return result;
  };

  let postDataChunks;

  if (button && button.dataset.target && button.dataset.target == "buy-all") {
    product_url = e.currentTarget.href;
    const split = product_url.split("add-to-cart=");
    if (split && split.length > 0) {
      const ids = split[1];
      const idsArray = ids?.split(",");

      postData = JSON.stringify({ addToCart: ids });
      postDataChunks = chunkArray(idsArray, 10);
    }
    // console.log("the product url", postData);
  }

  console.log("postDataChunks", postDataChunks);

  if (postDataChunks && postDataChunks?.length > 1) {
    Promise.all(
      postDataChunks?.map((postDataChunk) => {
        const postDataChunkString = JSON.stringify({
          addToCart: postDataChunk?.join(","),
        });

        jQuery.post(
          product_url,
          postDataChunkString + "&_wp_http_referer=" + product_url,
          function (result) {
            // console.log('result', result);
            addToCartBtnState(false);
            // let cart_dropdown = jQuery('#mini-cart', result)
            // let cart_icon = jQuery('#cart-contents', result)
            // console.log('the result', result)
            let woocommerce_success = jQuery(".woocommerce-message", result);
            let woocommerce_info = jQuery(".woocommerce-info", result);
            let woocommerce_error = jQuery(".woocommerce-error", result);
            let trackingInput = jQuery("#tpc-add-to-cart-tracking", result);

            let hasError;
            if (woocommerce_error.length > 0) {
              // console.log("has error 😞");
              for (let i = 0; i < woocommerce_error.length; i++) {
                // console.log("⛔️", woocommerce_error[i]);
              }
              hasError = true;
            } else if (woocommerce_success.length > 0) {
              hasError = false;
            }
            //console.log('success', woocommerce_success, 'info', woocommerce_info, 'error', woocommerce_error)

            // Show message
            if (hasError) {
              // console.log('1');
              jQuery(".type-product").eq(0).before(woocommerce_error);
            } else {
              // console.log('2');

              // update dropdown cart
              let errorContainer = jQuery(".woocommerce-error");
              if (errorContainer.length > 0) {
                jQuery(errorContainer).remove();
              }

              // console.log('cart_dropdown', cart_dropdown);
              // console.log('cart_icon', cart_icon);

              if (!isMobile()) {
                // const hiddenMinicart = document.getElementById("mini-cart-hidden");
                // const hiddenMinicartClone = hiddenMinicart.cloneNode(true);
                // hiddenMinicartClone.setAttribute("id", "mini-cart");
                // jQuery("#mini-cart").replaceWith(hiddenMinicartClone);
              }

              // console.log('hiddenMinicart', hiddenMinicart);
              // hiddenMinicart.setAttribute("id", "mini-cart");
              // jQuery('#mini-cart').replaceWith(hiddenMinicart)
              // const replacedMiniCart = document.getElementById('mini-cart-hidden')
              // replacedMiniCart.setAttribute("id", "mini-cart");
              // console.log(document.querySelectorAll('#mini-cart-hidden'));
              // jQuery('#cart-contents').replaceWith(cart_icon)

              if (trackingInput) {
                if (trackingInput.length > 1) {
                  const trackingData = [];
                  for (let i = 0; trackingInput.length > i; i++) {
                    const input = jQuery(trackingInput[i]);
                    trackingData.push(jQuery(input).data());
                  }
                  GA4AddToCart({ items: trackingData });
                } else {
                  const trackingData = jQuery(trackingInput).data();
                  GA4AddToCart({ items: [trackingData] });
                }
              }
            }
            // console.log("before ajax");

            // update fragments
            jQuery.ajax(warpFragmentRefresh);

            console.log("🧺 before mountMiniCart function");

            mountMiniCart(clickMaster);
          }
        );
      })
    );
  } else {
    jQuery.post(
      product_url,
      postData + "&_wp_http_referer=" + product_url,
      function (result) {
        // console.log('result', result);
        addToCartBtnState(false);
        // let cart_dropdown = jQuery('#mini-cart', result)
        // let cart_icon = jQuery('#cart-contents', result)
        // console.log('the result', result)
        let woocommerce_success = jQuery(".woocommerce-message", result);
        let woocommerce_info = jQuery(".woocommerce-info", result);
        let woocommerce_error = jQuery(".woocommerce-error", result);
        let trackingInput = jQuery("#tpc-add-to-cart-tracking", result);

        let hasError;
        if (woocommerce_error.length > 0) {
          // console.log("has error 😞");
          for (let i = 0; i < woocommerce_error.length; i++) {
            // console.log("⛔️", woocommerce_error[i]);
          }
          hasError = true;
        } else if (woocommerce_success.length > 0) {
          hasError = false;
        }
        //console.log('success', woocommerce_success, 'info', woocommerce_info, 'error', woocommerce_error)

        // Show message
        if (hasError) {
          // console.log('1');
          jQuery(".type-product").eq(0).before(woocommerce_error);
        } else {
          // console.log('2');

          // update dropdown cart
          let errorContainer = jQuery(".woocommerce-error");
          if (errorContainer.length > 0) {
            jQuery(errorContainer).remove();
          }

          // console.log('cart_dropdown', cart_dropdown);
          // console.log('cart_icon', cart_icon);

          if (!isMobile()) {
            // const hiddenMinicart = document.getElementById("mini-cart-hidden");
            // const hiddenMinicartClone = hiddenMinicart.cloneNode(true);
            // hiddenMinicartClone.setAttribute("id", "mini-cart");
            // jQuery("#mini-cart").replaceWith(hiddenMinicartClone);
          }

          // console.log('hiddenMinicart', hiddenMinicart);
          // hiddenMinicart.setAttribute("id", "mini-cart");
          // jQuery('#mini-cart').replaceWith(hiddenMinicart)
          // const replacedMiniCart = document.getElementById('mini-cart-hidden')
          // replacedMiniCart.setAttribute("id", "mini-cart");
          // console.log(document.querySelectorAll('#mini-cart-hidden'));
          // jQuery('#cart-contents').replaceWith(cart_icon)

          if (trackingInput) {
            if (trackingInput.length > 1) {
              const trackingData = [];
              for (let i = 0; trackingInput.length > i; i++) {
                const input = jQuery(trackingInput[i]);
                trackingData.push(jQuery(input).data());
              }
              GA4AddToCart({ items: trackingData });
            } else {
              const trackingData = jQuery(trackingInput).data();
              GA4AddToCart({ items: [trackingData] });
            }
          }
        }
        // console.log("before ajax");

        // update fragments
        jQuery.ajax(warpFragmentRefresh);

        console.log("🧺 before mountMiniCart function");

        mountMiniCart(clickMaster);
      }
    );
  }
};
